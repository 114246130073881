/* App.css */
.container {
  display: flex;
}

.sidebar {
  flex: 1;
  padding: 10px;
  border-right: 1px solid #ccc;
  overflow: hidden; /* Hide content when collapsed */
}

button {
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
}

button.no-margin {
  margin: 0;
}

.sidebar.collapsed {
  visibility: visible;
  flex: 0;
}

.collapsed {
  visibility: collapse;
}

.records {
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}

.images {
  flex: 3;
  padding: 10px;
  overflow-y: auto;
}

.card {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  background-color: #f7f7f7;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.record-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.record-info p {
  margin: 3px 10px;
}

.record-image {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
}

.images ul {
  padding: 0;
}

.images ul li {
  list-style: none;
  margin: 10px;
}

.images img {
  width: 100%;
  height: auto;
}

/* for mobile */
@media (max-width: 768px) {
  .sidebar {
    flex: 2;
  }
}

@media print {
  .header,
  .sidebar {
    display: none;
  }

  .images {
    width: 100%; /* Expand the width of the images column */
  }
}